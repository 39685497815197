<template>
  <v-dialog v-model="modal.toggle" persistent width="30%">
    <v-card>
      <v-container>
        <OrganizzeModalHeader
          :loading="loading"
          :isEdit="modal.isEdit"
          :title="contact.name || getModalTitle()"
          @onClose="closeModal()"
        />

        <v-tabs
          v-model="tab"
          style="margin-top: 16px; border-radius: 16px 16px 0px 0px !important;"
          :background-color="variables.colorPrimary"
          dark
          center-active
          show-arrows>
          <v-tabs-slider color="#000000"></v-tabs-slider>
          <v-tab href="#tab-0">Início</v-tab>
          <v-tab href="#tab-1">Informações fiscais</v-tab>
          <v-tab href="#tab-2">Endereço</v-tab>
          <v-tab href="#tab-3">Info</v-tab>
        </v-tabs>

        <v-form>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :key="0" :value="'tab-0'">
              <v-text-field
                label="Nome"
                v-model="contact.name"
                :disabled="loading"
                outlined>
              </v-text-field>

              <v-text-field
                label="Email"
                v-model="contact.email"
                :disabled="loading"
                outlined>
              </v-text-field>

              <v-text-field
                label="Celular"
                v-model="contact.phone"
                v-mask="['(##) # ####-####']"
                :disabled="loading"
                :error-messages="phoneErrors"
                @blur="$v.contact.phone.$touch()"
                outlined>
              </v-text-field>
            </v-tab-item>

            <v-tab-item :key="1" :value="'tab-1'">
              <v-row
                no-gutters
                style="justify-content: center;">
                <v-radio-group v-model="isCPF" row>
                  <v-radio label="CPF" :value="true"></v-radio>
                  <v-radio label="CNPJ" :value="false"></v-radio>
                </v-radio-group>
              </v-row>

              <v-col style="padding: 0px;">
                <v-text-field
                  label="CPF/CNPJ"
                  v-model="contact.cpfCnpj"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  :error-messages="cpfCnpjErrors"
                  :disabled="loading"
                  @blur="$v.contact.cpfCnpj.$touch()"
                  outlined>
                </v-text-field>

                <template v-if="!isCPF">
                  <v-text-field
                    label="Razão Social"
                    v-model="contact.companyName"
                    :disabled="loading"
                    outlined>
                  </v-text-field>

                  <v-text-field
                    label="Nome fantasia"
                    v-model="contact.tradingName"
                    :disabled="loading"
                    outlined>
                  </v-text-field>

                  <v-text-field
                    label="Inscrição municipal"
                    v-model="contact.cityRegistry"
                    :disabled="loading"
                    outlined>
                  </v-text-field>

                  <v-text-field
                    label="Inscrição estadual"
                    v-model="contact.stateRegistry"
                    :disabled="loading"
                    outlined>
                  </v-text-field>
                </template>
              </v-col>
            </v-tab-item>

            <v-tab-item :key="2" :value="'tab-2'">
              <v-text-field
                label="CEP"
                v-model="contact.addressJson.zipcode"
                :disabled="loading"
                outlined>
              </v-text-field>

              <v-text-field
                label="Logradouro"
                v-model="contact.addressJson.logradouro"
                :disabled="loading"
                outlined>
              </v-text-field>

              <v-row no-gutters>
                <v-text-field
                  label="Estado"
                  v-model="contact.addressJson.state"
                  style="margin-right: 16px;"
                  :disabled="loading"
                  outlined>
                </v-text-field>

                <v-text-field
                  label="Cidade"
                  v-model="contact.addressJson.city"
                  :disabled="loading"
                  outlined>
                </v-text-field>
              </v-row>

              <v-row no-gutters>
                <v-text-field
                  label="Bairro"
                  v-model="contact.addressJson.neighborhood"
                  style="margin-right: 16px;"
                  :disabled="loading"
                  outlined>
                </v-text-field>

                <v-text-field
                  label="Número"
                  v-model="contact.addressJson.number"
                  :disabled="loading"
                  outlined>
                </v-text-field>
              </v-row>

              <v-text-field
                label="Complemento"
                v-model="contact.addressJson.complement"
                :disabled="loading"
                outlined>
              </v-text-field>
            </v-tab-item>

            <v-tab-item :key="3" :value="'tab-3'">
              <v-textarea
                label="Informações adicionais"
                v-model="contact.memo"
                :disabled="loading"
                :aria-multiline="true"
                :error-messages="memoErrors"
                outlined>
              </v-textarea>
            </v-tab-item>

            <OrganizzeModalFooter
              v-if="tab === 'tab-3'"
              :loading="loading"
              @onConfirm="enviar"
            />
          </v-tabs-items>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import validation from '../validation'
import { required } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import { mapActions } from 'vuex'
import { isValidCpfOrCnpj } from '@/utils/validationUtils'

const namespaceStore = 'organizzeContatos'

export default {
  name: 'OrganizzeFinanceiroContatosModalCadastro',

  directives: { mask },

  data: (() => ({
    modal: {
      toggle: false,
      isEdit: false,
    },
    loading: false,
    skLoader: true,
    tab: 'tab-0',
    contact: {
      name: null,
      contactTypeId: 1,
      email: null,
      phone: null,
      cpfCnpj: null,
      companyName: null,
      tradingName: null,
      cityRegistry: null,
      stateRegistry: null,
      addressJson: {
        zipcode: null,
        logradouro: null,
        state: null,
        city: null,
        neighborhood: null,
        number: null,
        complement: null
      },
      memo: null
    },
    isCPF: true,
  })),

  created () {},

  mounted () {
    const self = this

    Events.$on('modalCadastro::open', data => {
      self.modal.toggle = true

      if (data.contact) {
        self.modal.isEdit = true
        self.contact = data.contact
        self.isCPF = self.contact.length === 11
      }

      self.contact.contactTypeId = (data.contactType || {}).id || self.contact.contactTypeId
    })
  },

  computed: {
    variables: () => variables,
    ...validation
  },

  components: {
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header'),
    OrganizzeModalFooter: () => import('@/views/components/organizze/modal/footer')
  },

  watch: {},

  methods: {
    ...mapActions(namespaceStore, ['submit']),
    getModalTitle () {
      const self = this
      const observer = {
        1: 'cliente',
        2: 'fornecedor',
        3: 'contato'
      }

      return observer[self.contact.contactTypeId]
    },

    closeModal () {
      const self = this
      self.tab = 'tab-0'
      self.modal.isEdit = false
      self.isCPF = true
      self.contact = {
        name: null,
        contactTypeId: 1,
        email: null,
        phone: null,
        cpfCnpj: null,
        companyName: null,
        tradingName: null,
        cityRegistry: null,
        stateRegistry: null,
        addressJson: {
          zipcode: null,
          logradouro: null,
          state: null,
          city: null,
          neighborhood: null,
          number: null,
          complement: null
        },
        memo: null
      }
      self.modal.toggle = false
    },

    enviar () {
      const self = this
      const rawPhone = String(this.$v.contact.phone.$model).replace(/\D/g, '')

      if (this.$v.$invalid || !isValidCpfOrCnpj(self.contact.cpfCnpj) || !(rawPhone.length === 11 || rawPhone.length === 0)) {
        errorSnackbar('Preencha os campos corretamente!')
        this.$v.$touch()
        return
      }

      self.loading = true

      if (self.contact.cpfCnpj) self.contact.cpfCnpj = String(self.contact.cpfCnpj).replace(/\D/g, '')

      self.submit({ data: self.contact, isEdit: self.modal.isEdit}).then(() => {
        if (!self.modal.isEdit) successSnackbar('Conta adicionada com sucesso.')
        else successSnackbar('Conta editada com sucesso.')
        Events.$emit('index::getContacts')
        self.closeModal()
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.loading = false
      })
    }
  },

  validations () {
    return {
      contact: {
        cpfCnpj: { required },
        memo: { required },
        phone: {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }

  .v-form {
    padding: 0px 16px 0px;
    border: 8px solid $colorPrimary;
    border-radius: 0px 0px 16px 16px !important;
  }

  .v-btn { text-transform: uppercase; }

  .toolBar {
    background-color: $colorPrimary !important;
    border-radius: 16px !important;
    display: flex;
    justify-content: center;
    margin: 0px;
    color: white;

    &-text {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .utilButton {
    color: $colorPrimaryDarken;
    border: 3px solid $colorPrimaryDarken;
    border-radius: 16px;


    &:hover {
      color: white;
      border: 3px solid white;
    }
  }
</style>