import { REQUIRED_FIELD, INVALID_FIELD } from '@/utils/validationMessagesUtils'
import { isValidCpfOrCnpj } from '@/utils/validationUtils'

export default {
  cpfCnpjErrors () {
    const errors = []
    if (!this.$v.contact.cpfCnpj.$dirty) return errors
    !this.$v.contact.cpfCnpj.required && errors.push(REQUIRED_FIELD)
    !isValidCpfOrCnpj(this.$v.contact.cpfCnpj.$model) && errors.push(INVALID_FIELD)
    return errors
  },

  memoErrors () {
    const errors = []
    if (!this.$v.contact.memo.$dirty) return errors
    !this.$v.contact.memo.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  phoneErrors () {
    const errors = []
    const rawPhone = String(this.$v.contact.phone.$model).replace(/\D/g, '')
    !(rawPhone.length === 11 || rawPhone.length === 0) && errors.push(INVALID_FIELD)
    return errors
  },
}